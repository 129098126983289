<template>
    <span>Hello</span>
</template>



<style scoped>
    span:hover {
        color: var(--color-theme);
        transition: .4s;
    }
</style>